@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
@import "./../../utils/AppColors";

.business-class {
  -ms-overflow-style: none;
  scrollbar-width: none;
  width: 90vw;
  max-height: 90vh;
  overflow-y: scroll;
}
.business-class-width {
  width: 78vw;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.business-class::-webkit-scrollbar {
  display: none;
}

.business-class-card {
  display: flex;
  height: auto;
  width: auto;
  background-color: $white;
  align-items: initial;
  margin-bottom: 2rem;
  border-radius: 10px;
  padding-left: 20px;
  padding-right: 20px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.business-class-top {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.business-class-top-left {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 20px;
  margin-top: 1rem;
}
.business-top-left-title {
  display: flex;
  font-weight: 500;
  font-size: 20px;
  color: $black;
}
.business-class-top-left-dropdown {
  margin-top: 2rem;
}
.business-class-top-right {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  align-items: end;
}
.business-class-table {
  display: flex;
  // overflow: scroll;
  margin: 2rem 0.5rem 1rem 0.5rem;
}
.business-class-pagination {
  display: flex;
  justify-content: flex-end;
  margin-right: 1.2rem;
}

.addCustomer-first {
  display: flex;
  flex-direction: column;
}
.addCustomer-title {
  color: $black;
  font-weight: 500;
  font-size: 20px;
}
.addCustomer-desc {
  margin-top: 10px;
  font-weight: 400;
  font-size: 15px;
}
.addretailer-second {
  height: 20rem;
  overflow-y: scroll;
}
.addCustomer-second-title {
  font-weight: 500;
  font-size: 14px;
  margin-left: 18px;
}
.addCustomer-second-hr hr {
  height: 1px;
  border: 0;
  background: black;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 10px;
  margin-bottom: 10px;
  width: auto;
}
.addCustomer-second-firstRow {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.addCustomer-second-secondRow {
  display: flex;
  justify-content: center;
  align-items: center;
}
.addCustomer-second-right-btn {
  float: right;
  margin-right: 1rem;
  margin-top: 20px;
  background: $black;
  border: $black;
  box-shadow: 0px 4px 6px -1px rgba(17, 17, 17, 0.1),
    0px 2px 4px -1px rgba(17, 17, 17, 0.06);
  border-radius: 5px;
  color: $white;
  font-weight: 500;
  font-size: 13px;
  padding: 8px 8px 8px 8px;
}
.addCustomer-second-right-btn-two {
  float: right;
  margin-top: 20px;
  background: $black;
  border: $black;
  box-shadow: 0px 4px 6px -1px rgba(17, 17, 17, 0.1),
    0px 2px 4px -1px rgba(17, 17, 17, 0.06);
  border-radius: 5px;
  color: $white;
  font-weight: 500;
  font-size: 13px;
  padding: 8px 8px 8px 8px;
}
.addCustomer-second-right-btn:focus {
  outline: none;
}
.addCustomer-second-right-btn-two:focus {
  outline: none;
}
.addCustomer-second-bottam-btn {
  display: flex;
  justify-content: center;
}
.addCustomer-second-bottam-btn-sub {
  font-weight: 500;
  font-size: 14px;
  color: $white;
  background: $black;
  border: $black;
  border-radius: 10px;
  padding: 10px 20px 10px 20px;
  transition: box-shadow 0.33s ease-in-out;
}
.addCustomer-second-bottam-btn-sub:hover {
  outline: none;
  box-shadow: 3px 3px 15px 3px #33b6d645;
}
.addCustomer-second-bottam-btn-can {
  margin-left: 10px;
  padding: 10px 20px 10px 20px;
  background: $white;
  color: $black;
  border-radius: 10px;
  font-weight: 500;
  font-size: 14px;
  border: 1px $black solid;
}
.addCustomer-second-bottam-btn-can:focus {
  outline: none;
}

.content-top-right-btn-close-icon {
  height: 15px;
  width: 15px;
}
.content-top-right-btn-close {
  background-color: transparent;
  border: none;
  float: right;
  margin-top: 10px;
  border-radius: 5px;
  margin-right: 1rem;
  height: 45px;
  padding: 10px;
}
.content-top-right-btn-close:focus {
  outline: none;
}
.franchise-scroll {
  height: 22rem;
  overflow-y: scroll;
}
.addbrand-info-btn {
  border: none;
  background-color: $black;
  color: $white;
  height: 50px;
  border-radius: 10px;
  width: 120px;
}
.del-pop {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}
.del-pop-icon {
  height: 50px;
  width: 50px;
}
.del-pop-text-div {
  display: flex;
  justify-content: center;
}
.del-pop-text {
  font-size: 22px;
  margin-top: 20px;
  text-align: center;
}
.del-pop-btn-yes {
  width: 120px;
  height: 40px;
  text-align: center;
  font-size: 20px;
  border-radius: 10px;
  margin-top: 20px;
  background-color: #f70d1a;
  color: #ffffff;
  border: none;
}
.del-pop-btn-no {
  width: 120px;
  height: 40px;
  text-align: center;
  font-size: 20px;
  border-radius: 10px;
  margin-left: 10px;
  margin-top: 20px;
  color: #000000;
  border: none;
}

.cl-content-details-left-side {
  display: flex;
  align-items: center;
}
.cl-content-details-left-name-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #84b7c3;
  border-radius: 8px;
  width: 58px;
  height: 58px;
}
.cl-content-details-left-name-logo-text {
  font-size: 30px;
  font-weight: 600;
}
.cl-content-details-left-name {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  color: #167a93;
  margin-left: 20px;
}
.cl-content-details-left-side-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  width: 200px;
}
.cl-content-details-left-side-dot {
  margin-right: 10px;
}
.cl-content-details-left-side-desc {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  width: 220px;
  margin-left: 20px;
}

@media (max-width: 920px) {
  .addretailer-second {
    height: 25rem;
    overflow-y: scroll;
  }
  .addCustomer-second-firstRow {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media (max-width: 510px) {
  .business-class-top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .business-class-top-left {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
  }
  .business-top-left-title {
    display: flex;
    font-weight: 500;
    font-size: 20px;
    color: $black;
  }
  .business-class-top-left-dropdown {
    margin-top: 2rem;
  }
  .business-class-top-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
  }
}
