@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
@import "./../../utils/AppColors";

.content-top-left-dropdown {
  margin-top: 20px;
  color: $darkGray;
}
.ac-inputText-input {
  border: none;
  box-shadow: none;
  --webkit-appearance: none;
  width: 100%;
  height: 35px;
  outline: none;
  padding-left: 5px;
  font-size: 18px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-family: "Inter";
}
.ac-inputText-input-wrapper {
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  margin-top: 1px;
  min-height: 40px;
}
.ac-inputText-input-container {
  flex-grow: 0;
  border: 1px solid $black;
  border-radius: 10px;
  position: relative;
  width: 259px;
  height: 35px;
  min-height: 40px;
  display: flex;
  align-items: center;
  padding-right: 10px;
  padding-left: 10px;
  font-size: 20px;
}
.ac-inputText-label {
  font-size: 15px;
  padding-left: 5px;
  padding-bottom: 5px;
}
