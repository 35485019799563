@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
@import "./../../utils/AppColors";

// Table {
//   width: 800px;
//   border-collapse: collapse;
//   border-radius: 1em;
//   overflow: hidden;
//   box-shadow: 0 1px 9px 1px rgba(0, 0, 0, 0.28);
//   -webkit-box-shadow: 0 1px 9px 1px rgba(0, 0, 0, 0.28);
// }
// Thead {
//   background-color: $black;
//   height: 50px;
// }
// Th {
//   text-align: center;
//   border-bottom: 1px solid $black;
//   border: 2px solid $black;
//   color: $white;
//   padding: 10px;
// }
// Td {
//   text-align: center;
//   font-size: 18px;
//   padding: 1rem;
//   border: 2px solid $black;
//   height: 3rem;
//   // min-height: 10rem;
//   min-width: 10rem;
// }

Th {
  text-align: center;
  color: $white;
  padding: 0.2rem;
}
Td {
  text-align: center;
  font-size: 18px;
  padding: 0.5rem;
}

Table {
  border-collapse: separate;
  border-spacing: 0;
}
Table Tr Th,
Table Tr Td {
  border-right: 1px solid $black;
  border-bottom: 1px solid $black;
  padding: 10px;
}

Table Tr Th:first-child,
Table Tr Td:first-child {
  border-left: 1px solid $black;
}
Table Tr Th {
  background-color: $black;
  text-align: center;
  border-top: solid 1px $black;
}

Table Tr:first-child Th:first-child {
  border-top-left-radius: 6px;
}

Table Tr:first-child Th:last-child {
  border-top-right-radius: 6px;
}

.table-verify {
  text-align: center;
  background: $black;
  border-radius: 5px;
  color: $white;
  font-weight: 500;
  font-size: 15px;
  padding: 5px;
  width: 100px;
}
.table-verified {
  text-align: center;
  border-radius: 5px;
  color: $black;
  font-weight: 500;
  font-size: 15px;
  padding: 5px;
  width: 100px;
}
.table-denied {
  text-align: center;
  border-radius: 5px;
  color: $black;
  font-weight: 500;
  font-size: 15px;
  padding: 5px;
  width: 100px;
  text-decoration: underline;
  cursor: pointer;
}
.cl-content-middle-action-data-div-delete {
  padding-left: 10px;
}
.sort-main {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 575px) {
  Td {
    text-align: center;
    font-size: 18px;
    padding: 1rem;
    border: 2px solid $black;
    height: 5rem;
    // min-height: 10rem;
    min-width: 10rem;
  }
}
