@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
@import "./../../utils/AppColors";

.addBrand-box-main {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 100;
}

.addBrand-box {
  position: relative;
  width: auto;
  margin: 15rem;
  height: auto;
  max-height: 90vh;
  margin-top: calc(100vh - 80vh - 20px);
  background: $white;
  border-radius: 4px;
  padding: 20px;
  border: 1px solid $gray;
  overflow: auto;
  padding: 20px 0px 20px 20px;
}

.deleteCustomer-box-main {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 100;
}
.deleteCustomer-box {
  position: relative;
  width: 520px;
  margin: 0 auto;
  height: auto;
  max-height: 90vh;
  margin-top: calc(100vh - 70vh - 20px);
  background: #fff;
  border-radius: 4px;
  padding: 20px;
  border: 1px solid #999;
  overflow: auto;
  border-radius: 10px;
}

@media (max-width: 1450px) {
  .addBrand-box {
    margin: 10rem;
  }
}
@media (max-width: 1250px) {
  .addBrand-box {
    margin: 5rem;
  }
}
@media (max-width: 1100px) {
  .addBrand-box {
    margin: 0;
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
}
@media (max-width: 500px) {
  .profile-box {
    width: auto;
    display: flex;
    justify-content: center;
    margin-left: 10px;
    margin-right: 10px;
  }
  .inputText-input-container {
    width: auto;
  }
  .profile-button-cancel {
    width: 100px;
  }
  .profile-button-change {
    width: 100px;
  }
}
