@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
@import "./../../utils/AppColors";

.input-class-wrapper {
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  min-height: 40px;
}
.input-label-div {
}
.input-label {
  font-size: 15px;
  padding-left: 5px;
}
.input-class-container {
  flex-grow: 0;
  border: 1px solid $black;
  border-radius: 10px;
  position: relative;
  width: 300px;
  height: 35px;
  min-height: 40px;
  display: flex;
  align-items: center;
  padding-right: 10px;
  padding-left: 10px;
  font-size: 20px;
  background-color: $white;
}
.input-class {
  margin-left: 0.1rem;
  border: none;
  box-shadow: none;
  --webkit-appearance: none;
  width: 100%;
  height: 35px;
  outline: none;
  padding-left: 5px;
  font-size: 18px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-family: "Inter";
}

.inputText-img {
  height: 20px;
  width: 20px;
}
.inputText-error-container {
  background-color: $lightPink;
  border-radius: 10px;
  padding: 4px 10px;
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.inputText-error-container span {
  margin-left: 10px;
  color: $red;
  font-size: 12px;
  font-weight: bold;
}
