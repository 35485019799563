.empty-table {
  height: 300px;
  width: 300px;
}
.empty-table-title {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  font-size: 20px;
  font-weight: 700;
}
