.inputText-input {
  border: none;
  box-shadow: none;
  --webkit-appearance: none;
  outline: none;
  width: 100%;
  padding-left: 5px;
  font-size: 18px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #f8f8f8;
  margin-left: 10px;
}
.inputText-input-wrapper {
  margin: 30px;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  min-height: 40px;
}
.inputText-input-container {
  flex-grow: 0;
  border: 1px solid #0000001f;
  border-radius: 4px;
  position: relative;
  width: 400px;
  height: 100%;
  min-height: 40px;
  display: flex;
  align-items: center;
  padding-right: 10px;
  padding-left: 10px;
}

.inputText-label {
  left: 1em;
  background-color: #f8f8f8;
  padding: 0 5px;
  font-size: 15px;
  font-weight: 400;
}
.inputText-img {
  height: 20px;
  width: 20px;
}
.inputText-error-container {
  background-color: #ffbbc2;
  border-radius: 10px;
  padding: 4px 10px;
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.inputText-error-container span {
  margin-left: 10px;
  color: #f44336;
  font-size: 12px;
  font-weight: bold;
}
