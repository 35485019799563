body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
* {
  font-family: "DejaVu Sans", sans-serif;
  /* text-transform: capitalize; */
}
* {
  scrollbar-width: auto;
  scrollbar-color: #a3a3a3 transparent !important;
}

*::-webkit-scrollbar {
  width: 12px !important;
}

*::-webkit-scrollbar-track {
  background-color: transparent !important;
}

*::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 10px;
  border: 5px solid transparent;
}
